















import Vue from 'vue';
import PrimaryButton from '@/views/components/button/primary-button.vue';

export default Vue.extend({
  name: 'first-checkup-block',
  components: { PrimaryButton },
  methods: {
    goToCheckup() {
      this.$router.push({ name: 'checkup' });
    },
  },
});
