





































import Vue from 'vue';
import { IHomeProduct } from '@/scripts/store/modules/home/types';
import TherapyTile from '@/views/products/navigation/therapy-tile.vue';

export default Vue.extend({
  name: 'therapy-group',
  components: {
    TherapyTile,
  },
  props: {
    therapies: {
      type: Array as () => IHomeProduct[],
    },
    limit: {
      type: Number,
      required: false,
    },
    recommendation: {
      type: String,
    },
  },
  data() {
    return {
      showMore: false,
    };
  },
  computed: {
    visibleTherapies(): Array<IHomeProduct> {
      if (!this.limit || this.showMore) return this.therapies;
      return this.therapies.length > this.limit ? this.therapies.slice(0, this.limit) : this.therapies;
    },
  },
});
