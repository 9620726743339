var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { row: "", wrap: "", "pt-3": "", "gap-xs-3": "" } },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c("h2", { staticClass: "starling-h2 mb-3" }, [
            _vm._v(_vm._s(_vm.$t("app.products.recommended.title")))
          ]),
          _c("p", { staticClass: "starling-explanatory mb-3" }, [
            _vm._v(_vm._s(_vm.$t("app.products.recommended.subtitle")))
          ]),
          _vm.checkupPending
            ? _c("first-checkup-block", {
                staticStyle: { "max-width": "790px" }
              })
            : _c("therapy-group", {
                attrs: {
                  therapies: _vm.recommendedTherapies,
                  recommendation: _vm.recommendation
                }
              })
        ],
        1
      ),
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c("h2", { staticClass: "starling-h2 mb-3" }, [
            _vm._v(_vm._s(_vm.$t("app.products.discover.title")))
          ]),
          _c("therapy-group", {
            attrs: {
              therapies: _vm.discoverTherapies,
              limit: _vm.$vuetify.breakpoint.mdAndUp ? 6 : 0,
              recommendation: _vm.recommendation
            }
          })
        ],
        1
      ),
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c("h2", { staticClass: "starling-h2 mb-3" }, [
            _vm._v(_vm._s(_vm.$t("app.products.completed.title")))
          ]),
          _c("p", { staticClass: "starling-explanatory mb-3" }, [
            _vm._v(_vm._s(_vm.$t("app.products.completed.subtitle")))
          ]),
          _c("therapy-group", {
            attrs: {
              therapies: _vm.completedTherapies,
              limit: _vm.$vuetify.breakpoint.mdAndUp ? 3 : 0
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }