var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "therapyGroup",
      staticClass: "therapy-group",
      class: _vm.$vuetify.breakpoint.name
    },
    [
      _vm.visibleTherapies && _vm.visibleTherapies.length
        ? [
            _c(
              "v-layout",
              {
                staticClass: "therapy-items",
                class: {
                  wrap: _vm.$vuetify.breakpoint.mdAndUp,
                  "-mx-3": _vm.$vuetify.breakpoint.smAndDown
                },
                attrs: { "gap-xs-2": "" }
              },
              [
                _vm._l(_vm.visibleTherapies, function(therapy, index) {
                  return [
                    _c(
                      "v-flex",
                      {
                        key: therapy.id,
                        staticClass: "therapy-item mb-1",
                        class: {
                          "pl-3":
                            _vm.$vuetify.breakpoint.smAndDown && index === 0,
                          "pr-3":
                            _vm.$vuetify.breakpoint.smAndDown &&
                            index === _vm.visibleTherapies.length - 1
                        },
                        attrs: { shrink: !_vm.$vuetify.breakpoint.smAndDown }
                      },
                      [
                        _c("therapy-tile", {
                          attrs: {
                            therapy: therapy,
                            recommended: therapy.id === _vm.recommendation
                          }
                        })
                      ],
                      1
                    )
                  ]
                }),
                _vm.limit && _vm.therapies.length > _vm.limit
                  ? _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c(
                          "v-layout",
                          { attrs: { "justify-center": "", "mt-2": "" } },
                          [
                            _c(
                              "v-flex",
                              { attrs: { shrink: "" } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "secondary-button d-block",
                                    staticStyle: { "min-width": "180px" },
                                    attrs: { outline: "", round: "" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        _vm.showMore = !_vm.showMore
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t(
                                            "app.products.buttons." +
                                              (_vm.showMore ? "less" : "more")
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              2
            )
          ]
        : [
            _c("v-flex", {
              staticClass: "empty-section",
              attrs: { xs12: "", "pa-2": "" }
            })
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }