






















import Vue from 'vue';
import { IHomeProduct, ITherapies } from '@/scripts/store/modules/home/types';
import FirstCheckupBlock from '@/views/products/navigation/first-checkup-block.vue';
import TherapyGroup from '@/views/products/navigation//therapy-group.vue';

export default Vue.extend({
  name: 'therapies-view',
  components: {
    FirstCheckupBlock,
    TherapyGroup,
  },
  data() {
    return {
      therapies: { recommended: [], discover: [], completed: [] } as ITherapies,
      checkupPending: false,
      showMoreDiscovery: false,
    };
  },
  computed: {
    completedTherapies(): Array<IHomeProduct> {
      return this.therapies.completed;
    },
    recommendedTherapies(): Array<IHomeProduct> {
      return this.therapies.recommended;
    },
    discoverTherapies(): Array<IHomeProduct> {
      return this.therapies.discover;
    },
    recommendation(): string | undefined {
      if (this.recommendedTherapies?.length) return this.recommendedTherapies[0].id;
      return undefined;
    },
  },
  mounted() {
    this.$store.commit('setLoadingView', true);

    this.$store.dispatch('home/getTherapies').then((res: ITherapies) => {
      this.checkupPending = !!res.firstCheckupDue;

      const recommendedPromises: Array<Promise<IHomeProduct>> = [];
      res.recommended.forEach((product: IHomeProduct) => {
        recommendedPromises.push(this.$store.dispatch('home/getTherapyInfo', product.id));
      });
      const recommendedPromise = Promise.all(recommendedPromises).then((res: Array<IHomeProduct>) => {
        this.therapies['recommended'] = res;
      });

      const discoverPromises: Array<Promise<IHomeProduct>> = [];
      res.discover.forEach((product: IHomeProduct) => {
        discoverPromises.push(this.$store.dispatch('home/getTherapyInfo', product.id));
      });
      const discoverPromise = Promise.all(discoverPromises).then((res: Array<IHomeProduct>) => {
        this.therapies['discover'] = res;
      });

      const completedPromises: Array<Promise<IHomeProduct>> = [];
      res.completed.forEach((product: IHomeProduct) => {
        completedPromises.push(this.$store.dispatch('home/getTherapyInfo', product.id));
      });
      const completedPromise = Promise.all(completedPromises).then((res: Array<IHomeProduct>) => {
        this.therapies['completed'] = res;
      });

      Promise.all([ recommendedPromise, discoverPromise, completedPromise ]).finally(() => {
        this.$store.commit('setLoadingView', false);
      });
    });
  },
});
