var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "first-checkup-card elevation-0 pa-2" },
    [
      _c(
        "v-layout",
        {
          class: _vm.$vuetify.breakpoint.xsOnly ? "wrap" : "",
          attrs: {
            "align-center": "",
            "justify-space-between": "",
            "gap-xs-2": ""
          }
        },
        [
          _c("v-flex", [
            _c("p", {
              staticClass: "starling-body StarlingLightGrey--text",
              domProps: {
                innerHTML: _vm._s(_vm.$t("app.products.first_checkup.text"))
              }
            })
          ]),
          _c(
            "v-flex",
            { attrs: { shrink: "", "text-center": "" } },
            [
              _c(
                "primary-button",
                {
                  staticStyle: { width: "150px" },
                  on: { click: _vm.goToCheckup }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("app.products.first_checkup.button")) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }