











































import Vue from 'vue';
import { HomeStatusEnum, IHomeProduct } from '@/scripts/store/modules/home/types';
import HorizontalSteps, { IHorizontalStep } from '@/views/components/steppers/horizontal-steps.vue';

export default Vue.extend({
  name: 'therapy-tile',
  components: {
    HorizontalSteps,
  },
  props: {
    therapy: Object as () => IHomeProduct,
    recommended: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      imageUrl: null as string | null,
      titleClamped: false,
      subtitleClamped: false,
    };
  },
  computed: {
    sessionSteps(): IHorizontalStep[] | undefined {
      let recommendedSession = this.recommended;
      return this.therapy.sessions?.map(session => {
        const step: IHorizontalStep = {
          text: session.order.toString(),
          status: session.status,
        };
        if (recommendedSession) {
          if (session.status === HomeStatusEnum.INITIAL) {
            step.status = HomeStatusEnum.RECOMMENDED.toString();
            recommendedSession = false;
          } else if (session.status === HomeStatusEnum.STARTED) {
            recommendedSession = false;
          }
        }
        return step;
      });
    },
    width(): String {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return '250px';
        case 'sm':
          return '300px';
        case 'md':
          return '250px';
        default:
          return '290px';
      }
    },
  },
  mounted() {
    this.generateImageUrl(this.therapy.image);

    if (this.$refs.title) {
      const elm = this.$refs.title as HTMLElement;
      this.titleClamped = elm.scrollHeight > elm.clientHeight;
    }
    if (this.$refs.subtitle) {
      const elm = this.$refs.subtitle as HTMLElement;
      this.subtitleClamped = elm.scrollHeight > elm.clientHeight;
    }
  },
  destroyed() {
    this.destroyImageUrl();
  },
  methods: {
    onExploreClick() {
      this.$router.push({
        name: 'products-run',
        params: { uen: this.therapy.uen },
      });
    },
    generateImageUrl(value?: string) {
      this.destroyImageUrl();
      if (value) {
        const blob = new Blob([ value ], { type: 'image/svg+xml' });
        this.imageUrl = URL.createObjectURL(blob);
      }
    },
    destroyImageUrl() {
      if (this.imageUrl) URL.revokeObjectURL(this.imageUrl);
      this.imageUrl = null;
    },
  },
});
