var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-hover", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var hover = ref.hover
          return _c(
            "v-card",
            {
              staticClass: "therapy-tile fill-height",
              class: [
                _vm.therapy.status.toLowerCase(),
                {
                  recommended: _vm.recommended,
                  extended: _vm.$vuetify.breakpoint.smAndDown
                }
              ],
              attrs: { elevation: hover ? 10 : 2 },
              on: { click: _vm.onExploreClick }
            },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _vm.imageUrl
                        ? _c("v-img", {
                            staticClass: "module-cover-image",
                            attrs: { src: _vm.imageUrl, height: "90px" }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    [
                      _c(
                        "v-layout",
                        {
                          attrs: {
                            column: "",
                            "fill-height": "",
                            "gap-xs-1": "",
                            "px-2": "",
                            "py-1": ""
                          }
                        },
                        [
                          _c("v-flex", [
                            _c(
                              "p",
                              {
                                ref: "title",
                                staticClass:
                                  "starling-body font-weight-550 tile-title"
                              },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: {
                                      bottom: "",
                                      disabled: !_vm.titleClamped,
                                      "content-class": "therapy-tile-tooltip"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c("span", _vm._g({}, on), [
                                                _vm._v(
                                                  _vm._s(_vm.therapy.title)
                                                )
                                              ])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.therapy.title))
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm.therapy.sessions && _vm.therapy.sessions.length
                            ? _c(
                                "v-flex",
                                [
                                  _c("horizontal-steps", {
                                    attrs: {
                                      steps: _vm.sessionSteps,
                                      dense: ""
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("v-flex", [
                            _c(
                              "p",
                              {
                                ref: "subtitle",
                                staticClass:
                                  "starling-small-text tile-description"
                              },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: {
                                      bottom: "",
                                      disabled: !_vm.subtitleClamped,
                                      "content-class": "therapy-tile-tooltip"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c("span", _vm._g({}, on), [
                                                _vm._v(
                                                  _vm._s(_vm.therapy.subtitle)
                                                )
                                              ])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.therapy.subtitle))
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }